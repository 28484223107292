body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Nunito', sans-serif;
}

header {
  background-color: #fff !important;
  color: #000 !important;
}